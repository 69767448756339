import React from "react";
import ImageContentComponent from "../ImageContent/index";
import home from "./../../json/home.json";
import "./HowItWorks.scss";

const HowItWorksComponent = () => {
  const howItWorks = home.howItWork;

  return (
    <div className="howItWorksContainer">
      <div className="howItWorks">
        <div
          className="howItWorks__left"
          data-aos="fade-right"
          data-aos-delay="10"
          data-aos-duration="800"
        >
          <h2 className="section-title">{home.whySquiril}</h2>
          {/* <p>
            {home.whySquirilPara}
          </p> */}
          {/* <a>{home.getSubscription}</a> */}
        </div>

        <div
          className="howItWorks__right"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="800"
        >
          {howItWorks &&
            howItWorks.map((item: any, index: number) => {
              return (
                <div className="img-dv" key={index}>
                  {
                    <ImageContentComponent
                      img={item.img}
                      stepName={item.stepName}
                      header={item.header}
                      content={item.content}
                      listContent={item?.listContent}
                    />
                  }
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default HowItWorksComponent;
