import * as React from "react";
import { useEffect } from "react";
import AOS from "aos";
// import * as Icons from "../icons";
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index";
import HomeBannerComponent from "../components/HomeBanner";
import HowItWorksComponent from "../components/HowItWorks";
import RecruitComponent from "../components/Recruit/Recruit.component";
// import ChooseYourDreamComponent from "../components/ChooseYourDream/index";
// import IncreaseTalentComponent from "../components/IncreaseTalent/IncreaseTalent.component";
// import BuildTeamComponent from "../components/BuildTeam";
// import home from "./../json/home.json";
import squirilImg from '../images/squiril_web.png';
import "./index.scss";
import AppYouNeedComponent from "../components/AppYouNeed";
import favIcon from '../images/FosterTalent_fav_icon.png';

export function Head() {
  return (
    <>
      <meta charSet="utf-8" />
      <title>Foster Talent</title>
      <meta name="title" content="Foster Talent"></meta>

      <meta property="og:title" name="title" content="Foster Talent"></meta>
      <meta name="twitter:title" content="Foster Talent"></meta>
    </>
  )
}

const HomeComponent = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Foster Talent</title>
        <meta name="title" content="Foster Talent"></meta>
        <link rel="icon" type="image/x-icon" href={favIcon} />
      </Helmet>

      <Layout>
        <HomeBannerComponent />

        <div className="how-works-btn top-btn" >
          {/* <a href="#aboutUs" className="primary-green-btn"></a> */}

          <img src={squirilImg} />
        </div>

        <div id="aboutUs">
          <RecruitComponent />
        </div>

        {/* <div className="how-works-btn">
        <a href="#howItsWorks" className="primary-green-btn">
          <Icons.DownArrow /> {home.seeHowItWorks}
        </a>
      </div> */}

        <div id="howItsWorks">
          <HowItWorksComponent />
        </div>

        <div>
          <AppYouNeedComponent />
        </div>

        {/* <ChooseYourDreamComponent /> */}
        {/* <IncreaseTalentComponent />
      <BuildTeamComponent /> */}
      </Layout>
    </>

  );
};

export default HomeComponent;
