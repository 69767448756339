import React from "react";
import home from "./../../json/home.json";
import "./HomeBanner.scss";

const HomeBannerComponent = () => {
  return (
    <div className="home-container">
      <h2
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-duration="800"
      >
        {home.welcome_header}
      </h2>

      <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
        Enhance Recruitment processes with the help of Automation and
        Pre-Screening Chat-bot to find your <span>Purple Squirrel</span>
      </p>

      {/* <div className="caro-dv">
        <div>
          <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">{home.improvedTalent}</p>
        </div>
        <div>
          <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">{home.preScreening}</p>
        </div>
        <div>
          <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">{home.timeReduction}</p>
        </div>
        <div>
          <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">{home.internalComminication}</p>
        </div>
      </div> */}

      <div
        className="get-started-form"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="800"
      >
        <div className="form-control">
          <input type="text" placeholder={home.workEmail} />

          <a
            href={process.env.GATSBY_SIGNUP_URL}
            type="button"
            className="primary-green-btn"
          >
            {home.getStarted}
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeBannerComponent;
