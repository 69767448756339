import React from 'react';
import appImg from '../../images/appNeed.svg';
import './AppYouNeed.scss';

const AppYouNeedComponent = ()=>{
    return(
        <div className='appNeedContainer'>
            <h2 data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">All the apps you need</h2>
            <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">Our integtations</p>
            <img data-aos="zoom-in" data-aos-delay="400" data-aos-duration="800" src={appImg} alt="" />
        </div>
    )
}

export default AppYouNeedComponent;