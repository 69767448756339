import React from "react";
import * as Icons from "./../../icons/index";
import home from "./../../json/home.json";
import "./Recruit.scss";

const RecruitComponent = () => {
  return (
    <div className="recruit-container">
      <h2
        className="section-title"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="800"
      >
        How much time do you spend to recruit a Talent?
      </h2>

      <div className="recruit-container__body">
        <div className="recruit-container__body__before-after">
          <div
            className="box"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="800"
          >
            <Icons.BeforeHrsense />
            <h3>{home.beforeHrSense}</h3>
            <p>{home.beforeHrSenseParaph}</p>
          </div>

          <div
            className="box"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="800"
          >
            <span>{home.vs}</span>
          </div>

          <div
            className="box"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="800"
          >
            <Icons.AfterHrsense />
            <h3>{home.afterHrSense}</h3>
            <p>{home.afterHrSenseParaph}</p>
          </div>
        </div>

        <div
          className="recruit-container__body__img-dv"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="800"
        >
          <img
            src="https://hr-sense-public-assets.s3.ap-south-1.amazonaws.com/website/recruit_img.png"
            alt=""
          />
        </div>

        <div
          className="recruit-container__body__total-dv"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <Icons.TotalHrsense />
          <h4>
            <span>{home.sixtyPercentage}</span> {home.mechanicalWork} <br />
            {home.workReduced}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default RecruitComponent;
