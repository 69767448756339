import React from "react";
import "./ImageContent.scss";

const ImageContentComponent = ({
  img,
  stepName,
  header,
  content,
  listContent = [],
}: any) => {
  return (
    <div className="imageContantSection">
      <div className="imageContant">
        <div className="imageContant__content">
          <h6>{stepName}</h6>
          <h2>{header}</h2>
          <p>
            {content &&
              content.length > 0 &&
              content.map((item: any, index: number) => (
                <p key={index}>{item}</p>
              ))}
          </p>
          <ul>
            {listContent &&
              listContent.length > 0 &&
              listContent.map((item: any) => (
                <li dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
          </ul>
        </div>

        <div
          className="imageContant__img"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="800"
        >
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ImageContentComponent;
